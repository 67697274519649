var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(_vm.planInfoComponent, {
        tag: "component",
        attrs: { assessPlan: _vm.assessPlan, disabled: _vm.disabled },
        on: {
          "update:assessPlan": function ($event) {
            _vm.assessPlan = $event
          },
          "update:assess-plan": function ($event) {
            _vm.assessPlan = $event
          },
        },
      }),
      _c(_vm.targetComponent, {
        tag: "component",
        attrs: { assessPlan: _vm.assessPlan, disabled: _vm.disabled },
        on: {
          "update:assessPlan": function ($event) {
            _vm.assessPlan = $event
          },
          "update:assess-plan": function ($event) {
            _vm.assessPlan = $event
          },
        },
      }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }